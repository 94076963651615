import type { OAuthError, User } from '@auth0/auth0-react';
import { useAuth0 } from '@auth0/auth0-react';
import { KargoFullPageLoadingSkeleton } from '@components/kargo-ui/full-page-loading-skeleton';
import styled from '@emotion/styled';
import { setUser } from '@sentry/nextjs';
import { createContext, useEffect, useRef, useState } from 'react';
import { useLogger } from '../logging';
import { useMediaQuery } from '@mui/material';
import { MOBILE_VIEW_WIDTH } from 'constants/global';
import { MobileKargoFullPageLoadingSkeleton } from '@components/kargo-ui/mobile/full-page-loading-skeleton';

const StyledAuth0FullContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
`;

const StyledAuth0ErrorText = styled.p`
  font-size: 1.5rem;
`;

// 10 hour expiry for Auth0 token
const TOKEN_REFRESH_RATE = 36000000;

type Auth0TokenContextProps = {
  auth0User: User | null;
  token: string;
  setAuth0Token: (newToken: string) => void;
  hasPermission: (permission: string) => boolean;
};

const Auth0TokenContext = createContext<Auth0TokenContextProps>({
  auth0User: null,
  token: '',
  setAuth0Token() {},
  hasPermission() {
    return false;
  },
});

type Auth0TokenContextProviderProps = {
  children: React.ReactNode;
};

const Auth0TokenProvider = ({
  children,
}: Auth0TokenContextProviderProps): JSX.Element => {
  const {
    isAuthenticated: isAuth0Authenticated,
    isLoading: isAuth0Loading,
    user: auth0User,
    error: auth0Error,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();
  const logger = useLogger();

  const [token, setToken] = useState<string>();
  const tokenFetchInterval = useRef<ReturnType<typeof setInterval> | null>(
    null,
  );

  const isMobileView = useMediaQuery(`(max-width: ${MOBILE_VIEW_WIDTH})`);

  // Fetch Auth0 token and set refresh interval
  useEffect(() => {
    if (isAuth0Loading) {
      return;
    }

    const getToken = async () => {
      try {
        const newToken = await getAccessTokenSilently();

        setToken(newToken);
      } catch (err) {
        // This is needed because package auth0-react does not have types exported for errors
        const auth0TokenError = err as OAuthError;

        if (auth0TokenError.error === 'login_required') {
          loginWithRedirect({
            appState: { returnTo: window.location.pathname },
          });

          return;
        }

        if (auth0TokenError.error === 'consent_required') {
          loginWithRedirect({
            appState: { returnTo: window.location.pathname },
          });

          return;
        }

        throw err;
      }
    };

    getToken();
    tokenFetchInterval.current = setInterval(getToken, TOKEN_REFRESH_RATE);

    return () => {
      if (tokenFetchInterval.current) {
        clearTimeout(tokenFetchInterval.current);
      }
    };
  }, [getAccessTokenSilently, loginWithRedirect, isAuth0Loading]);

  // Try to re-log if Auth0 was not authenticated
  if (!isAuth0Loading && !isAuth0Authenticated) {
    loginWithRedirect({ appState: { returnTo: window.location.pathname } });

    return (
      <>
        {isMobileView ? (
          <MobileKargoFullPageLoadingSkeleton />
        ) : (
          <KargoFullPageLoadingSkeleton />
        )}
      </>
    );
  }

  if (auth0Error) {
    logger.error(
      'Dashboard: Failed to login due to Auth0 error in frontend',
      auth0Error,
    );

    return (
      <StyledAuth0FullContainer>
        <StyledAuth0ErrorText>
          Sorry, something went wrong. Please try again or contact an
          administrator.
        </StyledAuth0ErrorText>
      </StyledAuth0FullContainer>
    );
  }

  // Set sentry user for tracking
  if (auth0User) {
    setUser({
      username: auth0User.email,
      name: auth0User.name,
    });
  } else {
    setUser(null);
  }

  const permissions: string[] = token
    ? JSON.parse(
        token
          .split('.')
          .map((a) => window.atob(a.replace(/_/g, '/').replace(/-/g, '+')))[1],
      )['permissions']
    : [];

  return (
    <Auth0TokenContext.Provider
      value={{
        auth0User: auth0User ?? null,
        token: token ?? '',
        setAuth0Token: (newToken) => {
          setToken(newToken);
        },
        hasPermission(permission) {
          return permissions.includes(permission);
        },
      }}
    >
      {isAuth0Loading ? (
        <>
          {isMobileView ? (
            <MobileKargoFullPageLoadingSkeleton />
          ) : (
            <KargoFullPageLoadingSkeleton />
          )}
        </>
      ) : (
        children
      )}
    </Auth0TokenContext.Provider>
  );
};

export { Auth0TokenContext, Auth0TokenProvider };
