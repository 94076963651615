import { Logo } from '@components';
import { KargoDrawer } from '@components/kargo-ui/drawer';
import { DashboardAdminLinkButton } from '@components/layout';
import styled from '@emotion/styled';
import { ApolloClientProvider } from '@kargo/context/apollo-client';
import {
  Auth0TokenContext,
  Auth0TokenProvider,
} from '@kargo/context/auth0-token';
import { AuthenticatedUserProvider } from '@kargo/context/authenticated-user';
import { FacilityProvider } from '@kargo/context/facility';
import { PermissionsProvider } from '@kargo/context/permissions';
import { DashboardDemoButton } from '@layouts/dashboard/demo-button';
import { MenuOpen } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { PERMISSIONS_MAP } from 'constants/permissions-map';
import Link from 'next/link';
import { useContext, useEffect, useState } from 'react';
import {
  DASHBOARD_NAV_HEADER_HEIGHT,
  MOBILE_DASHBOARD_CONTAINER_HEIGHT,
  MOBILE_DASHBOARD_NAV_HEADER_HEIGHT,
  SIDE_NAV_WIDTH,
} from './constants';
import { DashboardActionsMenu } from './dashboard-header/actions-menu';
import { DashboardHeaderNavigation } from './dashboard-header/dashboard-header-navigation';
import type { DashboardBreadcrumb } from './dashboard-header/dashboard-header-navigation/dashboard-header-navigation';
import { DashboardNotifications } from './dashboard-header/dashboard-notifications';
import { DashboardUserSettingsMenu } from './dashboard-header/dashboard-user-settings-menu';
import { DashboardNav } from './dashboard-nav';
import { DashboardReportIssue } from './report-issue';
import { useGate } from 'statsig-react';
import { useRouter } from 'next/router';
import { usePermissions } from '@hooks/kargo-ui/use-permissions';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MOBILE_VIEW_WIDTH } from 'constants/global';

const StyledDashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(p) => p.theme.colors.contentPrimary};
  background: ${(p) => p.theme.colors.backgroundPrimary};
  overflow: hidden;
`;

type StyledDashboardContentContainerProps = {
  isNavDrawerOpen: boolean;
};

const StyledDashboardContentContainer = styled.div<StyledDashboardContentContainerProps>`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${DASHBOARD_NAV_HEADER_HEIGHT});
  width: ${(p) =>
    p.isNavDrawerOpen ? `calc(100% - ${SIDE_NAV_WIDTH})` : '100%'};
  margin-left: ${(p) => (p.isNavDrawerOpen ? `${SIDE_NAV_WIDTH}` : 0)};
  transition: all 0.2s ease-out;

  background: ${({ theme }) => theme.colors.backgroundPrimary};
  color: ${({ theme }) => theme.colors.contentPrimary};
  overflow: auto;
`;

type StyledDashboardHeaderContainerProps = {
  isNavDrawerOpen: boolean;
};

const StyledDashboardHeaderContainer = styled.div<StyledDashboardHeaderContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${DASHBOARD_NAV_HEADER_HEIGHT};
  width: ${(p) =>
    p.isNavDrawerOpen ? `calc(100% - ${SIDE_NAV_WIDTH} + 55px)` : '100%'};
  margin-left: ${(p) =>
    p.isNavDrawerOpen ? `calc(${SIDE_NAV_WIDTH} - 55px)` : 0};
  transition: all 0.2s ease-out;

  padding: 8px 24px;
  background: ${(p) => p.theme.colors.backgroundPrimary};
  border-bottom: 1px solid ${(p) => p.theme.colors.borderPrimary};
`;

const StyledDashboardHeaderMain = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StyledOpenNavDrawerIconButton = styled(IconButton)`
  transition: opacity 0.1s ease-in;

  :hover {
    opacity: 0.6;
    background: none;
  }
`;

const StyledOpenNavDrawerIcon = styled(MenuOpen)`
  transform: scaleX(-1);
`;

const StyledDashboardHeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledNavContainer = styled.div`
  width: ${SIDE_NAV_WIDTH};
  min-height: 100vh;
  color: ${(p) => p.theme.colors.contentPrimary};
  background-color: ${(p) => p.theme.colors.sidebarBackground};
  overflow: auto;
`;

const StyledNavLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${DASHBOARD_NAV_HEADER_HEIGHT};
  background-color: ${(p) => p.theme.colors.black};
`;

const StyledLogoLink = styled.a`
  margin-left: 26px;

  :focus-visible {
    outline: 1px auto ${(p) => p.theme.colors.white};
  }
`;

const StyledDashboardFabItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  padding: 0 44px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 12px;
`;

const StyledCloseNavDrawerIconButton = styled(IconButton)`
  padding-top: 10px;
  transition: opacity 0.1s ease-in;

  :hover {
    opacity: 0.6;
  }

  :focus-visible {
    border-radius: 0;
    outline: 1px auto ${(p) => p.theme.colors.white};
  }
`;

const StyledCloseNavDrawerIcon = styled(MenuOpen)`
  font-size: 28px;
  color: ${(p) => p.theme.colors.white};
`;

// Mobile CSS

const StyledMobileViewContainer = styled.div`
  max-width: ${MOBILE_VIEW_WIDTH};
  height: ${MOBILE_DASHBOARD_CONTAINER_HEIGHT};
  background: ${({ theme }) => theme.colors.backgroundPrimary};
  color: ${({ theme }) => theme.colors.contentPrimary};
`;

const StyledMobileDashboardHeaderContainer = styled.div`
  height: ${MOBILE_DASHBOARD_NAV_HEADER_HEIGHT};
  position: sticky;
  top: 0;
  z-index: 100;
`;

const StyledDashboardHeaderNavigationContainer = styled.div`
  position: sticky;
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  padding: 8px 12px;
  top: ${MOBILE_DASHBOARD_NAV_HEADER_HEIGHT};
  border-bottom: 1px solid ${(p) => p.theme.colors.borderPrimary};
  z-index: 1000;
`;

type StyledMobileNavContainerType = {
  navMenuOpen: boolean;
};

const StyledMobileNavContainer = styled.div<StyledMobileNavContainerType>`
  width: 100vw;
  height: 100vh;
  color: ${(p) => p.theme.colors.contentPrimary};
  background-color: ${(p) => p.theme.colors.sidebarBackground};
  overflow: auto;
`;

const StyledMenuHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${DASHBOARD_NAV_HEADER_HEIGHT};
  background-color: ${(p) => p.theme.colors.black};
`;

const StyledMenuIcon = styled(MenuIcon)`
  font-size: 28px;
  color: ${(p) => p.theme.colors.white};
`;

const StyledMenuCloseIcon = styled(CloseIcon)`
  font-size: 28px;
  color: ${(p) => p.theme.colors.white};
`;

const StyledMobileDashboardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: ${MOBILE_DASHBOARD_CONTAINER_HEIGHT};
  width: 100%;
  margin-left: 0;
  transition: all 0.2s ease-out;
  overflow: auto;
`;

const DashboardNavActionsContainer = styled.div`
  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 12px;
  border-top: 1px solid ${(p) => p.theme.colors.black};
`;

type DashboardLayoutContentProps = {
  breadcrumbs?: DashboardBreadcrumb[];
  children: React.ReactNode;
};

const DashboardLayoutContent = ({
  breadcrumbs,
  children,
}: DashboardLayoutContentProps) => {
  const { hasPermission } = useContext(Auth0TokenContext);

  const [isNavDrawerOpen, setIsNavDrawerOpen] = useState<boolean>(true);
  const [isNavMenuOpen, setIsNavMenuOpen] = useState<boolean>(false);

  const canSeeKargoEmployeeButtons = hasPermission(
    PERMISSIONS_MAP.INTERNAL_USER,
  );

  const isMobileView = useMediaQuery(`(max-width: ${MOBILE_VIEW_WIDTH})`);

  return (
    <>
      {isMobileView && (
        <StyledMobileViewContainer>
          <StyledMobileDashboardHeaderContainer>
            <StyledNavLogoContainer>
              <Link href='/' passHref>
                <StyledLogoLink>
                  <Logo />
                </StyledLogoLink>
              </Link>

              <StyledCloseNavDrawerIconButton
                onClick={() => {
                  setIsNavMenuOpen(true);
                }}
              >
                <StyledMenuIcon />
              </StyledCloseNavDrawerIconButton>
            </StyledNavLogoContainer>
          </StyledMobileDashboardHeaderContainer>

          <StyledDashboardHeaderNavigationContainer>
            <DashboardHeaderNavigation breadcrumbs={breadcrumbs} />
          </StyledDashboardHeaderNavigationContainer>

          <StyledMobileDashboardContentContainer>
            {children}
          </StyledMobileDashboardContentContainer>

          <KargoDrawer
            variant='persistent'
            anchor='right'
            classes={{
              paper: 'dashboard-nav-drawer',
            }}
            open={isNavMenuOpen}
          >
            <StyledMobileNavContainer navMenuOpen={isNavMenuOpen}>
              <StyledMenuHeaderContainer>
                <Link href='/' passHref>
                  <StyledLogoLink>
                    <Logo />
                  </StyledLogoLink>
                </Link>
                <StyledCloseNavDrawerIconButton
                  onClick={() => {
                    setIsNavMenuOpen(false);
                  }}
                >
                  <StyledMenuCloseIcon />
                </StyledCloseNavDrawerIconButton>
              </StyledMenuHeaderContainer>

              <DashboardNav
                onNavItemClick={() => {
                  setIsNavMenuOpen(false);
                }}
              />

              <DashboardNavActionsContainer>
                <DashboardUserSettingsMenu />

                <DashboardNotifications />
              </DashboardNavActionsContainer>
            </StyledMobileNavContainer>
          </KargoDrawer>
        </StyledMobileViewContainer>
      )}

      {!isMobileView && (
        <StyledDashboardContainer>
          <StyledDashboardHeaderContainer isNavDrawerOpen={isNavDrawerOpen}>
            <StyledDashboardHeaderMain>
              <StyledOpenNavDrawerIconButton
                onClick={() => {
                  setIsNavDrawerOpen(true);
                }}
              >
                <StyledOpenNavDrawerIcon />
              </StyledOpenNavDrawerIconButton>

              <DashboardHeaderNavigation breadcrumbs={breadcrumbs} />
            </StyledDashboardHeaderMain>

            <StyledDashboardHeaderActions>
              <DashboardActionsMenu />

              <DashboardUserSettingsMenu />

              <DashboardNotifications />
            </StyledDashboardHeaderActions>
          </StyledDashboardHeaderContainer>

          <StyledDashboardContentContainer isNavDrawerOpen={isNavDrawerOpen}>
            {children}
          </StyledDashboardContentContainer>

          <KargoDrawer
            variant='persistent'
            anchor='left'
            classes={{
              paper: 'dashboard-nav-drawer',
            }}
            open={isNavDrawerOpen}
          >
            <StyledNavContainer>
              <StyledNavLogoContainer>
                <Link href='/' passHref>
                  <StyledLogoLink>
                    <Logo />
                  </StyledLogoLink>
                </Link>

                <StyledCloseNavDrawerIconButton
                  onClick={() => {
                    setIsNavDrawerOpen(false);
                  }}
                >
                  <StyledCloseNavDrawerIcon />
                </StyledCloseNavDrawerIconButton>
              </StyledNavLogoContainer>

              <DashboardNav />

              {canSeeKargoEmployeeButtons && (
                <StyledDashboardFabItemsContainer>
                  <DashboardAdminLinkButton />
                  <DashboardDemoButton />
                  <DashboardReportIssue />
                </StyledDashboardFabItemsContainer>
              )}
            </StyledNavContainer>
          </KargoDrawer>
        </StyledDashboardContainer>
      )}
    </>
  );
};

type Props = DashboardLayoutContentProps;

const DashboardLayout = (props: Props): JSX.Element => {
  return (
    <Auth0TokenProvider>
      <ApolloClientProvider>
        <AuthenticatedUserProvider>
          <FacilityProvider>
            <PermissionsProvider>
              <RouteAccessControl>
                <DashboardLayoutContent {...props} />
              </RouteAccessControl>
            </PermissionsProvider>
          </FacilityProvider>
        </AuthenticatedUserProvider>
      </ApolloClientProvider>
    </Auth0TokenProvider>
  );
};

const RouteAccessControl = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const router = useRouter();
  const { permissions } = usePermissions();

  const { value: shouldShowOnlyForklift } = useGate(
    'should_show_only_forklift',
  );

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (shouldShowOnlyForklift) {
      if (router.pathname.indexOf('/forklift-sessions') !== 0) {
        router.replace('/forklift-sessions');
      }
    } else if (router.pathname === '/') {
      if (permissions.hasBusinessSettingsPermission) {
        router.replace('/home');
      } else {
        router.replace('/shipments');
      }
    }
    // NextJS does not have good support with useEffect and router
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowOnlyForklift, permissions.hasBusinessSettingsPermission]);

  return <>{children}</>;
};

export { DashboardLayout };
