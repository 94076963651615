import { Logo } from '@components/logo';
import styled from '@emotion/styled';
import { DASHBOARD_NAV_HEADER_HEIGHT } from '@layouts/dashboard/constants';
import CircularProgress from '@mui/material/CircularProgress';
import Link from 'next/link';

const StyledNavLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${DASHBOARD_NAV_HEADER_HEIGHT};
  background-color: ${(p) => p.theme.colors.black};
`;

const StyledLogoLink = styled.a`
  margin-left: 26px;

  :focus-visible {
    outline: 1px auto ${(p) => p.theme.colors.white};
  }
`;

const StyledMobileKargoFullPageLoadingSkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.backgroundPrimary};
  height: 100vh;
`;

const StyledLoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${(p) => p.theme.colors.gray400};
`;

const MobileKargoFullPageLoadingSkeleton = () => {
  return (
    <StyledMobileKargoFullPageLoadingSkeletonContainer>
      <StyledNavLogoContainer>
        <Link href='/' passHref>
          <StyledLogoLink>
            <Logo />
          </StyledLogoLink>
        </Link>
      </StyledNavLogoContainer>

      <StyledLoadingContainer>
        <StyledCircularProgress />
      </StyledLoadingContainer>
    </StyledMobileKargoFullPageLoadingSkeletonContainer>
  );
};

export { MobileKargoFullPageLoadingSkeleton };
