import { gql, useQuery } from '@apollo/client';
import { KargoFullPageLoadingSkeleton } from '@components/kargo-ui/full-page-loading-skeleton';
import { useFacility } from 'hooks/kargo-ui/use-facility';
import type {
  PermissionsContextQuery,
  PermissionsContextQueryVariables,
} from 'generated/graphql';
import { createContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { MOBILE_VIEW_WIDTH } from 'constants/global';
import { MobileKargoFullPageLoadingSkeleton } from '@components/kargo-ui/mobile/full-page-loading-skeleton';

const PERMISSIONS_CONTEXT_QUERY = gql`
  query PermissionsContextQuery($businessId: Int!) {
    business(id: $businessId) {
      id
      users {
        hasPermission
      }
    }
  }
`;

type DashboardPermissions = {
  hasBusinessSettingsPermission: boolean;
};

type PermissionsContextType = {
  permissions: DashboardPermissions | null;
};

const PermissionsContext = createContext<PermissionsContextType>({
  permissions: null,
});

type Props = {
  children: React.ReactNode;
};

const PermissionsProvider = ({ children }: Props): JSX.Element => {
  const { facility } = useFacility();

  const isMobileView = useMediaQuery(`(max-width: ${MOBILE_VIEW_WIDTH})`);

  const {
    data: permissionsContextQueryData,
    loading: permissionsContextQueryLoading,
    error: permissionsContextQueryError,
  } = useQuery<PermissionsContextQuery, PermissionsContextQueryVariables>(
    PERMISSIONS_CONTEXT_QUERY,
    {
      fetchPolicy: 'cache-first',
      variables: {
        businessId: facility.businessId,
      },
    },
  );

  if (permissionsContextQueryError) {
    throw new Error('Permissions could not be fetched');
  }

  const permissions: DashboardPermissions | null = permissionsContextQueryData
    ? {
        hasBusinessSettingsPermission:
          permissionsContextQueryData.business.users.hasPermission,
      }
    : null;

  return (
    <PermissionsContext.Provider
      value={{
        permissions,
      }}
    >
      {permissionsContextQueryLoading || !permissions ? (
        <>
          {isMobileView ? (
            <MobileKargoFullPageLoadingSkeleton />
          ) : (
            <KargoFullPageLoadingSkeleton />
          )}
        </>
      ) : (
        children
      )}
    </PermissionsContext.Provider>
  );
};

export { PermissionsContext, PermissionsProvider };
