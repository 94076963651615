import { Icon } from '@components/kargo-ui';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Link from 'next/link';
import { useRouter } from 'next/router';

type StyledNavLabelProps = {
  isHighlighted: boolean;
};

const StyledNavLabel = styled.p<StyledNavLabelProps>`
  display: flex;
  margin-left: 14px;
  font-weight: ${(p) => (p.isHighlighted ? 600 : 400)};
`;

type StyledNavLinkProps = {
  isSelected: boolean;
  isHighlighted: boolean;
  isSubNav?: boolean;
};

const StyledNavLink = styled.a<StyledNavLinkProps>`
  ${({ theme: { colors }, isSelected, isHighlighted, isSubNav }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    transition: all 0.4s ease-out;
    padding: 12px 4px 12px 24px;

    background-color: ${isSelected
      ? colors.sidebarSelectedBackground
      : colors.sidebarUnselectedBackground};

    color: ${isSelected || isHighlighted
      ? colors.sidebarSelectedText
      : colors.sidebarUnselectedText};

    cursor: pointer;
    font-size: 0.875rem;

    :hover {
      color: ${colors.white};
    }

    :focus-visible {
      outline: 1px auto ${colors.white};
    }

    // Sub-navigation link styles
    ${isSubNav &&
    css`
      padding: 8px 4px 8px 24px;
      margin-left: 16px;
      font-weight: 400;
    `}
  `}
`;

export type NavLinkItemProps = {
  label: string;
  href: string;
  isDisabled?: boolean;
  iconComponent?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  isVisible?: boolean;
};

type Props = {
  navLinkItem: NavLinkItemProps;
  onNavItemClick: () => void;
};

const NavItem = ({ navLinkItem, onNavItemClick }: Props): JSX.Element => {
  const { route } = useRouter();
  const { colors } = useTheme();
  const { label, href, isDisabled = false, iconComponent } = navLinkItem;

  const isSelected = href === route;
  const mainParentPath = route.split('/').slice(0, 2).join('/');
  const isHighlighted = !!mainParentPath && mainParentPath === href;

  return (
    <>
      {!isDisabled && (
        <Link href={href} passHref>
          <StyledNavLink
            isSelected={isSelected}
            isHighlighted={isHighlighted}
            onClick={onNavItemClick}
          >
            {iconComponent && (
              <Icon
                color={
                  isSelected || isHighlighted ? colors.white : colors.gray500
                }
                strokeWidth={isSelected || isHighlighted ? 2 : 1.5}
                icon={iconComponent}
              />
            )}

            <StyledNavLabel isHighlighted={isHighlighted}>
              {label}
            </StyledNavLabel>
          </StyledNavLink>
        </Link>
      )}

      {isDisabled && (
        <StyledNavLink isSelected={isSelected} isHighlighted={isHighlighted}>
          {iconComponent && (
            <Icon
              color={
                isSelected || isHighlighted ? colors.white : colors.gray500
              }
              strokeWidth={isSelected || isHighlighted ? 2 : 1.5}
              icon={iconComponent}
            />
          )}

          <StyledNavLabel isHighlighted={isHighlighted}>{label}</StyledNavLabel>
        </StyledNavLink>
      )}
    </>
  );
};

export { NavItem };
