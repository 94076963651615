import { gql, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { KargoFullPageLoadingSkeleton } from '@components/kargo-ui/full-page-loading-skeleton';
import { KargoLogo } from '@components/kargo-ui/icons/logo';
import { MobileKargoFullPageLoadingSkeleton } from '@components/kargo-ui/mobile/full-page-loading-skeleton';
import styled from '@emotion/styled';
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import { MOBILE_VIEW_WIDTH } from 'constants/global';
import type {
  FacilityContextBusinessesFragment,
  FacilityContextConfigFragment,
  FacilityContextQuery,
  FacilityContextQueryVariables,
} from 'generated/graphql';
import moment from 'moment';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLogger } from '../logging';
import { StatsigClientContext } from '../statsig-client';

const FACILITY_CONTEXT_CONFIG_FRAGMENT = gql`
  fragment FacilityContextConfigFragment on FacilityFeatureConfig {
    manualMultiShipmentCheckinEnabled
  }
`;

const FACILITY_CONTEXT_BUSINESSES_FRAGMENT = gql`
  fragment FacilityContextBusinessesFragment on Business {
    id
    name
    slug
    facilities {
      id
      name
      slug
      timezone {
        name
      }
      featureConfig {
        ...FacilityContextConfigFragment
      }
    }
  }
  ${FACILITY_CONTEXT_CONFIG_FRAGMENT}
`;

const FACILITY_CONTEXT_QUERY = gql`
  query FacilityContextQuery {
    businesses {
      id
      facilities {
        id
      }
      ...FacilityContextBusinessesFragment
    }
  }
  ${FACILITY_CONTEXT_BUSINESSES_FRAGMENT}
`;

const StyledFacilityContextFullContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  height: 100vh;
  padding: 20px;
`;

const StyledFacilityContextErrorText = styled.p`
  font-size: 1.5rem;
`;

const StyledKargoLogo = styled(KargoLogo)`
  font-size: 80px;
`;

const StyledLogoutButton = styled(Button)`
  font-size: 1rem;
  text-transform: none;
  color: ${(p) => p.theme.colors.gray500};

  :hover {
    background: none;
    color: ${(p) => p.theme.colors.black};
  }
`;

const FACILITY_CACHE_VERSION = '1.7';

export type DashboardFacility = {
  id: number;
  name: string;
  slug: string;
  businessId: number;
  businessSlug: string;
  businessName: string;
  timezoneName: string;
  timezoneAbbr: string;
  configurations: FacilityContextConfigFragment;
};

function cacheSelectedFacility(facility: DashboardFacility) {
  sessionStorage.setItem(
    `selectedFacility_${FACILITY_CACHE_VERSION}`,
    JSON.stringify(facility),
  );

  localStorage.setItem(
    `selectedFacility_${FACILITY_CACHE_VERSION}`,
    JSON.stringify(facility),
  );
}

function loadCachedFacility() {
  const session = sessionStorage.getItem(
    `selectedFacility_${FACILITY_CACHE_VERSION}`,
  );

  const local = localStorage.getItem(
    `selectedFacility_${FACILITY_CACHE_VERSION}`,
  );

  return session ?? local ?? null;
}

function formatFacilities(
  businesses: FacilityContextBusinessesFragment[],
): DashboardFacility[] {
  return businesses.flatMap((business) =>
    business.facilities.map(({ id, name, slug, timezone, featureConfig }) => ({
      id,
      name: name ?? '',
      slug,
      businessId: business.id,
      businessSlug: business.slug ?? '',
      businessName: business.name ?? '',
      timezoneName: timezone?.name ?? moment.tz.guess(),
      timezoneAbbr: moment.tz(timezone?.name ?? moment.tz.guess()).zoneAbbr(),
      configurations: featureConfig,
    })),
  );
}

type FacilityContextType = {
  facility: DashboardFacility | null;
  availableFacilities: DashboardFacility[] | null;
  setFacility: (selectedFacility: DashboardFacility) => void;
};

const FacilityContext = createContext<FacilityContextType>({
  facility: null,
  availableFacilities: null,
  setFacility() {},
});

type Props = {
  children: React.ReactNode;
};

const FacilityProvider = ({ children }: Props) => {
  const { setSelectedFacilityId } = useContext(StatsigClientContext);
  const { logout } = useAuth0();
  const logger = useLogger();

  const isMobileView = useMediaQuery(`(max-width: ${MOBILE_VIEW_WIDTH})`);

  const {
    data: facilityContextQueryData,
    loading: facilityContextQueryLoading,
    error: facilityContextQueryError,
  } = useQuery<FacilityContextQuery, FacilityContextQueryVariables>(
    FACILITY_CONTEXT_QUERY,
    {
      fetchPolicy: 'cache-first',
    },
  );

  const [selectedFacility, setSelectedFacility] =
    useState<DashboardFacility | null>(null);
  const [availableFacilities, setAvailableFacilities] = useState<
    DashboardFacility[]
  >([]);

  useEffect(() => {
    if (!facilityContextQueryData) {
      return;
    }

    setAvailableFacilities(
      formatFacilities(facilityContextQueryData.businesses),
    );
  }, [facilityContextQueryData]);

  useEffect(() => {
    const cachedFacility = loadCachedFacility();

    let facilityToSet: DashboardFacility | null = null;
    if (cachedFacility) {
      const facility = JSON.parse(cachedFacility);

      facilityToSet = facility;
    } else if (facilityContextQueryData?.businesses) {
      const availableFacilities = formatFacilities(
        facilityContextQueryData?.businesses,
      );

      facilityToSet = availableFacilities[0];
    }

    if (facilityToSet) {
      setSelectedFacility(facilityToSet);
      setSelectedFacilityId(facilityToSet.id);
      cacheSelectedFacility(facilityToSet);
    }
  }, [facilityContextQueryData, setSelectedFacilityId]);

  const handleUpdateFacility = useCallback(
    (updatedFacility: DashboardFacility) => {
      setSelectedFacility(updatedFacility);
      setSelectedFacilityId(updatedFacility.id);
      cacheSelectedFacility(updatedFacility);
    },
    [setSelectedFacilityId],
  );

  if (facilityContextQueryError) {
    if (
      facilityContextQueryError.graphQLErrors[0].extensions.code ===
      'UNAUTHENTICATED'
    ) {
      return (
        <StyledFacilityContextFullContainer>
          <StyledKargoLogo />

          <StyledFacilityContextErrorText>
            You do not have access to the system. Please request access from an
            administrator.
          </StyledFacilityContextErrorText>

          <StyledLogoutButton
            variant='text'
            onClick={() => {
              logout();
            }}
          >
            Log Out
          </StyledLogoutButton>
        </StyledFacilityContextFullContainer>
      );
    }

    logger.error(
      'Dashboard: Failed facility-context fetch on frontend',
      facilityContextQueryError,
    );

    <StyledFacilityContextFullContainer>
      <StyledFacilityContextErrorText>
        Sorry, something went wrong. Please try again or contact an
        administrator.
      </StyledFacilityContextErrorText>
    </StyledFacilityContextFullContainer>;

    throw new Error('Facilities could not be fetched');
  }

  const existingFacilities = facilityContextQueryData
    ? formatFacilities(facilityContextQueryData.businesses)
    : null;
  const sortedAvailableFacilities = availableFacilities.sort(
    (facilityOne, facilityTwo) => {
      const facilityOneLabel = `${facilityOne.businessName} - ${facilityOne.name}`;
      const facilityTwoLabel = `${facilityTwo.businessName} - ${facilityTwo.name}`;

      return facilityOneLabel > facilityTwoLabel ? 1 : -1;
    },
  );

  if (existingFacilities?.length === 0) {
    return (
      <StyledFacilityContextFullContainer>
        <StyledKargoLogo />

        <StyledFacilityContextErrorText>
          There was an issue fetching your credential information. Please try
          again or contact an administrator.
        </StyledFacilityContextErrorText>

        <StyledLogoutButton
          variant='text'
          onClick={() => {
            logout();
          }}
        >
          Log Out
        </StyledLogoutButton>
      </StyledFacilityContextFullContainer>
    );
  }

  return (
    <FacilityContext.Provider
      value={{
        facility: selectedFacility,
        setFacility: handleUpdateFacility,
        availableFacilities: sortedAvailableFacilities,
      }}
    >
      {facilityContextQueryLoading || !selectedFacility ? (
        <>
          {isMobileView ? (
            <MobileKargoFullPageLoadingSkeleton />
          ) : (
            <KargoFullPageLoadingSkeleton />
          )}
        </>
      ) : (
        children
      )}
    </FacilityContext.Provider>
  );
};

export { FacilityContext, FacilityProvider };
